const credits = {
  'American Battlefield Trust and SC Battleground Trust': {
    employees: [
      'Catherine Noyes',
      'Doug Bostick',
      'Lawrence Swiader',
      'Dan Davis',
      'Brigid Leavy',
    ],
    roles: [
      'Liberty Trail Program Director',
      'CEO - South Carolina Battleground Preservation Trust',
      'Chief Digital Officer',
      'Education Manager',
      'Institutional Giving Manager',
    ],
  },
  'Plus & Greater Than': {
    employees: [
      'Traci Sym',
      'Daniel Meyers',
      'Laura Allcorn',
      'David Beauvais',
      'Chris Dewan',
      'Marc Lehman'],
    roles: [
      'Founding Principal',
      'Founding Principal',
      'Experience Designer + Content Strategist',
      'Managing Director',
      'Design Director',
      'Motion Designer',
    ],
  },
  Collaborators: {
    employees: [
      'Phil Gaines',
      'Justin Sherin',
      'Chris Funk',
      'Martha Rich',
      'Steelab',
      'Kayleen King',
      'Dave Colvin',
    ],
    roles: [
      'The Man Who Made Things Happen',
      'Playwright',
      'Composer',
      'Illustrator',
      'Fabrication',
      'Musician',
      'Musician',
    ],
  },
  'AV&C': {
    employees: [
      'Richard Mullen',
      'Jon Toposki',
      'Nate Blum',
      'Branden Hall',
      'Daniel Vargas',
    ],
    roles: [
      'Senior Producer',
      'Senior Software Producer',
      'Senior Engineer',
      'Director of Software',
      'Jr. Software Developer',
    ],
  },
  'Principal Cast': {
    employees: [
      'Danielle Barker',
      'Wesley Stace',
      'Justin Spencer',
      'JJ Surma',
      'David Shaughnessy',
      'Larry Herron',
      'Heather Kae Smith',
      'Alex Kumin',
      'Katherine Grant Suttie',
      'DB Cooper',
      'Ian James Corlett',
      'Ian James Corlett',
      'Adam Schulmerich',
      'Conor McGee',
      'Andrew Amador',
      'Manny Cristobal',
      'Gregory Fields',
      "Keith O'Brien",
      'Jalen Askins',
      'Jeffrey Holz',
      'Vince Brady',
      'Richard Tatum',
      'Jalen Askins',
      'Adam Schulmerich',
      'Clayton Froning',
      'Nick Green',
    ],
    roles: [
      'Narrator',
      'Narrator',
      'Lt. Gen Charles Cornwallis',
      'Pvt. Guilford Dudley',
      'Gen. Horatio Gates',
      'Primus',
      'Wagoneer',
      'Wagoneer',
      'Wagoneer',
      'Wagoneer',
      'Lt. Col Charles Porterfield',
      'Col. Otho Holland Williams',
      'Hogan',
      'Pvt Garrett Watts',
      'Dr. Hugh Williamson',
      'Patriot',
      'Patriot',
      'Patriot',
      'Dragoon',
      'Dragoon',
      'Lt. Col. Banastre Tarleton',
      'Loyalist',
      'Black Loyalist',
      'Soldier',
      'Soldier',
      'Sgt. Roger Lamb',
    ],
  },
};

export default credits;
