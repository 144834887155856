import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import ActOne from '../views/ActOneView.vue';
import ActTwo from '../views/ActTwoView.vue';
import utils from '@/utils';
import HandIcon from '@/assets/media/Camden_Hand.png';
import ABTLogo from '@/assets/media/Camden_ABT_Green.png';
import LocationIcon from '@/assets/media/Camden_Point_Marker.png';
import CamdenMap from '@/assets/media/map.png';
import PortraitIcon from '@/assets/media/portrait_icon.png';
import Interstitial from '@/assets/media/interstitial.png';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    beforeEnter() {
      const media = [HandIcon, ABTLogo, LocationIcon, CamdenMap, PortraitIcon, Interstitial];
      media.forEach((img) => {
        utils.loadImage(img);
      });
    },
  },
  {
    path: '/act1',
    name: 'ActOne',
    component: ActOne,
  },
  {
    path: '/act2',
    name: 'ActTwo',
    component: ActTwo,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
