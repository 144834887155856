<template>
  <div class="act-1-container">
    <Navbar></Navbar>
    <div @click="onGoHome" class="home-link">
      <p class='header'>Voices of Camden</p>
    </div>
    <div class="acts-container">
      <p class="act-1-header">Act 1</p>
      <h1 class="act-1-title">On the Road</h1>
      <div @click="(e) => onToggleAudioPlay(e)" class="play-btn">
        <i v-if="!$store.state.isAudioPlaying" class="bi bi-play-fill play-pause-icon"></i>
        <i v-else class="bi bi-pause play-pause-icon margin-l"></i>
      </div>
      <p class="act-1-subheader">{{ roundedAudioDuration }}</p>
      <hr class="divider">
      <p class="header-description">Hop in your car, buckle up, and press play.
        We'll be following the route of the British.
        They started their journey here too. Let's see
        where the ended up.
      </p>
    </div>
    <Footer></Footer>
    <div class="playbar-container">
      <div ref="progressContainer"
        @pointerdown.prevent="(e) => startDrag(e)"
        @click.prevent="(e) => seek(e)"
        class="progress-container">
        <div ref="progress" class="progress" id="progress">
          <div ref="currTime" class="current-time"></div>
        </div>
      </div>
      <p class="playbar-title">Act 1: The Road We Travel</p>
      <p ref="audioDuration" class="playbar-duration"></p>
      <div @click="(e) => onToggleAudioPlay(e)" class="play-btn">
        <i v-if="!$store.state.isAudioPlaying" class="bi bi-play-fill play-pause-icon"></i>
        <i v-else class="bi bi-pause play-pause-icon"></i>
        <audio crossOrigin="anonymous" preload="auto" ref="act1Audio" id="act1Audio">
          <source src="@/assets/media/Act_OneDraft_Audio.mp3" type="audio/mpeg">
          Your browser does not support the audio element.
          <a href="@/assets/media/Act_OneDraft_Audio.mp3">link to download the audio</a> instead.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
  computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import utils from '@/utils';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'ActOne',
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      act1Audio: null,
      progressContainer: null,
      progress: null,
      timer: null,
      audioDuration: null,
      roundedAudioDuration: null,
      percent: 0,
      windowWidth: null,
      currTime: null,
    });
    const width = computed(() => data.windowWidth);
    const onResize = () => {
      data.windowWidth = window.innerWidth;
    };
    const handleProgressBar = (duration, element) => {
      const increment = 10 / duration;
      data.percent = Math.min(increment * element.currentTime * 10, 100);
      data.progress.style.width = `${data.percent}%`;
      if (width.value > 500) {
        data.currTime.style.left = `${((data.percent) / 388) * width.value}px`;
      } else {
        data.currTime.style.left = `${((data.percent - 2) / 100) * width.value}px`;
      }
      if (data.percent <= 100) {
        data.timer = setTimeout(() => { handleProgressBar(duration, element); }, 10);
      }
    };
    const seek = (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      if (!data.act1Audio) return;
      const bounds = e.target.getBoundingClientRect();
      const seekPos = Math.min((e.clientX - bounds.left) / bounds.width, 100);
      data.act1Audio.currentTime = data.act1Audio.duration * seekPos;
    };
    const stopDrag = () => {
      data.currTime.style.top = '-5px';
      data.currTime.style.width = '12px';
      data.currTime.style.height = '12px';
      data.progressContainer.removeEventListener('pointermove', seek);
      data.progressContainer.removeEventListener('pointerup', stopDrag);
    };
    const startDrag = () => {
      data.currTime.style.top = '-8px';
      data.currTime.style.width = '20px';
      data.currTime.style.height = '20px';
      data.progressContainer.addEventListener('pointermove', seek);
      data.progressContainer.addEventListener('pointerup', stopDrag);
    };
    const onToggleAudioPlay = () => {
      store.dispatch('setAudio', !store.state.isAudioPlaying);
      if (store.state.isAudioPlaying) {
        data.act1Audio.play();
      } else {
        data.act1Audio.pause();
      }
      data.act1Audio.addEventListener('playing', (e) => {
        const { duration } = e.target;
        handleProgressBar(duration, data.act1Audio, e);
      });
      data.act1Audio.addEventListener('pause', () => {
        clearTimeout(data.timer);
      });
      data.act1Audio.onended = () => {
        data.currTime.style.left = '0px';
        data.progress.style.width = 0;
        data.act1Audio.currentTime = 0;
        store.state.isAudioPlaying = false;
        router.push({ path: '/act2' });
      };
    };
    onBeforeMount(() => {
      window.scrollTo(0, 0);
    });
    onMounted(() => {
      store.dispatch('setAudio', false);
      data.windowWidth = window.innerWidth;
      window.addEventListener('resize', onResize);
      document.addEventListener('touchstart', (e) => {
        if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;
        e.preventDefault();
      });
    });
    watch(() => [data.act1Audio, data.audioDuration], (audio) => {
      /* eslint-disable no-param-reassign */
      const updateAudioTimer = setInterval(() => {
        data.roundedAudioDuration = utils.formatAudioDuration(audio[0].duration);
        const currentTime = utils.convertTime(audio[0].currentTime);
        const totalTime = utils.convertTime(audio[0].duration);
        audio[1].innerHTML = `${currentTime} / ${totalTime}`;
      }, 100);
      return () => {
        clearInterval(updateAudioTimer);
      };
      /* eslint-enable no-param-reassign */
    });

    const onGoHome = () => {
      store.dispatch('setAudio', false);
      router.push({ path: '/' });
    };
    return {
      ...toRefs(data),
      onToggleAudioPlay,
      handleProgressBar,
      onGoHome,
      seek,
      startDrag,
      stopDrag,
      width,
      onResize,
    };
  },
};
</script>

<style lang="scss" scoped>
.act-1-container {
  position: relative;
  margin-top: 0px;
  height: 100%;
  overflow-x: hidden;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  background-color: rgba(11,83,64,255);
  touch-action: pan-y !important;
  .home-link {
    position: relative;
    top: 20px;
    left: 10px;
    text-decoration: none;
    width: 200px;
    z-index: 0;
    .header {
      margin-top: 10%;
      margin-left: 5%;
      font-size: 18px;
      font-weight: bold;
      color: #f3cfb7;
      text-align: start;
      cursor: pointer;
    }
  }

  .acts-container {
    position: relative;
    background-color: rgba(11,83,64,255);
    color: rgba(236,95,86,255);
    padding: 50px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: rgba(236,95,86,255);
      width: 100%;
      margin: 40px 0px;
    }
    .act-1-header {
      font-size: 24px;
    }
    .act-1-subheader {
      margin-top: 30px;
      font-family: Karla-Medium;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .act-1-title {
      margin: 0;
      font-size: 44px;
      width: 250px;
      text-align: center;
      margin-bottom: 20px;
    }
    .play-btn, .swipe-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      cursor: pointer;
      .play-pause-icon {
        color: rgba(236,95,86,255);
        font-size: 60px;
        font-weight: bold;
        margin-top: -10px;
        margin-left: 5px;
      }
      .margin-l {
        margin-right: 5px;
      }
    }
    .header-description {
      font-weight: normal;
      font-size: 24px;
      margin: 0;
    }
  }
  .playbar-container {
    position: fixed;
    bottom: 0;
    height: 100px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #fff;
    background-color: rgba(11,83,64,255);
    .progress-container {
      position: relative;
      background-color: transparent;
      width: 100vw;
      max-width: 500px;
      height: 40px;
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height: 4px;
        max-width: 500px;
        background-color: #fff;
        transition: width .1s linear;
        .current-time {
          position: relative;
          top: -5px;
          left: 0px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
          transition: left .1s linear, width .1s ease-in-out;
        }
      }
    }
    .playbar-title, .playbar-duration {
      text-align: start;
      color: #fff;
      font-size: 16px;
      margin-left: 20px;
      margin-top: 0;
    }
    .playbar-duration {
      font-size: 10px;
    }
    .play-btn {
      position: absolute;
      top: 20%;
      right: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      cursor: pointer;
      .play-pause-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        color: rgba(236,95,86,255);
        font-size: 38px;
      }
    }
  }
}
</style>
