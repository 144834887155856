<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 4</p>
      <p class="scene-title">Preparedness</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Hogan, Col. Otho Holland Williams, Gen. Horatio Gates</p>
        <p class="country">British</p>
        <p class="names">Lt. Gen. Charles Cornwallis</p>
      </div>
      <div class="script-container">
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">You think the dead don’t dream?</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">The men are stalks and scarecrows.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">At Deep River I swam for hard peaches.
            I dream of them ever since. Oh, lovely.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">Nevertheless, General, as we bid for high ground,
            pray consider an indirect route –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">Not in their shape –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">For weeks march eighteen hours on green corn,
            peach feels better than a woman.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">There’s food to be had –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">I won’t march up the garden path, Colonel.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">The district crawls with Tories –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">Half our men will drop before we reach Camden.
            Go while we have life!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">The population is friendly. But I have eight hundred sick men.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">We have beef, and quick bread, and molasses.
            That will carry them eight miles. With the British laid low –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">We’ll strike before fever takes us.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">Sir –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">We are twice their number.
            You’ll grant Saratoga proves my way with the British mind?</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Silence.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">What that molasses did to my belly –
            I prayed for death before it came.</dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          HOGAN (d. 1780) While his unit was starving and hemmed in by floods,
          he and another man swam the Deep River to fetch unripe peaches.
          He lies on the Camden battlefield.
        </p>
        <p class="bio-desc">
          OTHO HOLLAND WILLIAMS (1749-94) was the former clerk of Frederick County,
          MD, and a merchant. He volunteered for the Continental Army in 1775,
          and was commissioned a Captain in a rifle regiment. He saw action at
          Boston and Fort Washington, where he was taken prisoner and sent to
          New York. His rank afforded him limited parole—in the streets he
          sneered at his captors, and spied for the Continental Army. Caught,
          he was imprisoned. This broke his health; but on his release in 1778,
          he went straight to the southern campaign, where he distinguished himself
          with bravery and strategic sense. A favorite of George Washington,
          he retired as a Brigadier General.
        </p>
        <p class="bio-desc">
          HORATIO GATES (1727-1806) was born at Maldon, Essex, the son
          of a customs inspector. He purchased an Army commission, and
          served in the War of Austrian Succession and the Seven Years’ War.
          But he couldn’t get on, so he sold his commission and moved to Virginia,
          where he ingratiated himself with George Washington, who appointed him
          Adjutant General in 1775. Gates believed his own publicity as the
          ‘Hero of Saratoga’—he wasn’t, Burgoyne lost it. He tried to use his
          fame to supplant Washington, but it didn’t work. After Camden his
          political allies saved him from court martial. He never directly
          commanded troops again.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          CHARLES CORNWALLIS (1738-1805), son of an Earl, was a veteran
          of the Seven Years’ War. He was second-in-command of British troops
          for most of the revolution, and their most gifted strategist.
          But Camden likely turned him cocky, as Saratoga did to Gates.
          He was later Lord Lieutenant of Ireland and Governor-General of India.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
