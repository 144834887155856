export default {
  convertTime: (audio) => {
    let mins = Math.floor(audio / 60);
    let secs = Math.floor(audio % 60);
    if (secs < 10) {
      secs = `0${String(secs)}`;
    }
    if (mins < 10) {
      mins = `0${String(mins)}`;
    }
    return `${mins}:${secs}`;
  },
  formatAudioDuration: (duration) => {
    const mins = Math.round(duration / 60);
    return `Run time ${mins} minutes`;
  },
  loadImage: (imagesObject) => {
    const img = new Image();
    img.src = imagesObject;
    return img.src;
  },
};
