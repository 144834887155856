<template>
  <div class="popup-container">
    <i @click="onClosePopup" class="bi bi-x cancel-btn"></i>
    <div v-if="page === 'homepage'">
      <p class="popup-text">This website has been optimized for mobile
        devices and is best viewed in portrait mode.</p>
      <img class="portrait-icon" src="@/assets/media/portrait_icon.png"
        alt="Mobile device icon">
    </div>
    <div class="intro-popup" v-else>
      <p class="header-description">Swipe left/right to explore
        the scenes of the play.
      </p>
      <svg xmlns="http://www.w3.org/2000/svg"
        :style="windowWidth < 400 ? { top: '18px' } : { top: '25px' }"
        class="swipe-left-arrow"
        viewBox="0 0 1700 100">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7"
          refX="0" refY="3.5" fill="rgba(236,95,86,255)" orient="auto">
            <polygon points="0 0, 3 3.5, 0 7" />
          </marker>
        </defs>
        <line x1="600" y1="50" x2="100" y2="50" stroke="rgba(236,95,86,255)"
        stroke-dasharray="25"
        stroke-width="8" marker-end="url(#arrowhead)" />
      </svg>
      <img preload="auto" class="hand-icon"
        src="@/assets/media/Camden_Hand.png" alt="pointing hand icon">
      <svg xmlns="http://www.w3.org/2000/svg"
        class="swipe-right-arrow"
        viewBox="0 0 1700 100">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7"
          refX="0" refY="3.5" fill="rgba(236,95,86,255)" orient="auto">
            <polygon points="0 0, 3 3.5, 0 7" />
          </marker>
        </defs>
        <line x1="1100" y1="50" x2="1600" y2="50" stroke="rgba(236,95,86,255)"
        stroke-dasharray="25"
        stroke-width="8" marker-end="url(#arrowhead)" />
      </svg>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    page: String,
  },
  setup() {
    const store = useStore();
    const state = reactive({
    });
    const windowWidth = ref(null);

    const onClosePopup = () => {
      document.body.classList.remove('no-scroll');
      store.dispatch('setPopup', false);
    };

    const onResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      windowWidth.value = window.innerWidth;
      window.addEventListener('resize', onResize);
    });

    return {
      ...toRefs(state),
      onClosePopup,
      onResize,
      windowWidth,
    };
  },
};
</script>

<style lang="scss" scoped>

.popup-container {
  color: rgba(236,95,86,255);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 70%);
  z-index: 5;
  height: 250px;
  width: 250px;
  background-color: #f3cfb7;
  padding: 30px;
  border-radius: 8px;

  .intro-popup {
    padding-top: 50px;
  }

  .popup-text, .cancel-btn {
    font-family: BeirutDisplayTrial;
    font-style: normal;
    font-weight: normal;
    color: rgba(236,95,86,255);
    text-align: center;
    font-size: 24px;
    margin: 0;
  }
  .cancel-btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    font-size: 50px;
    cursor: pointer;
  }
  .portrait-icon {
    margin-top: 25px;
    position: relative;
    left: 37%;
    width: 60px;
    height: 60px;
  }
  .swipe-left-arrow {
    position: relative;
    top: 25px;
  }
  .swipe-right-arrow {
    position: relative;
    bottom: 54px;
  }
  .hand-icon {
    margin-top: 5px;
    width: 38px;
    height: 50px;
  }
}

</style>
