<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 3</p>
      <p class="scene-title">Taking Sides</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Patriot Voices, Primus, Wagoneers</p>
        <p class="country">British</p>
        <p class="names">Loyalists, Black Loyalists</p>
      </div>
      <div class="script-container">
        <dl class="scene-script">
          <dt class="speaker">PATRIOTS</dt>
          <dd class="text">London gets fat off my corn.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My sugar.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Stamp every scrap of paper for a thousand miles.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Soldiers in my barn.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My wool gone worthless.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">They’ll hang us in England.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Tax us in the grave.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Screw us under the bishop’s drape.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">And what do we get?</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Knife to the throat.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Kick up the arse.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">No more voice than a dog.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My pa was run out of England for being poor.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">For praying.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Because he weren’t a lickspittle to lords.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">LOYALIST</dt>
          <dd class="text">South Carolina born, and English, by God!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PATRIOT</dt>
          <dd class="text">It’s the bullet and sword now.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">LOYALIST</dt>
          <dd class="text">I chop up snakes with a shovel.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PATRIOT</dt>
          <dd class="text">Only thing to stop it.</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>This might be a place to pause. Or not.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">BRITISH 1</dt>
          <dd class="text">King’s soldiers don’t go hungry.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PRIMUS</dt>
          <dd class="text">Best pay for a Negro this side of heaven.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">BRITISH 2 (Yorkshire)</dt>
          <dd class="text">Might as well die for glory than die in mill.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">BLACK LOYALIST</dt>
          <dd class="text">Fight for the King who set me free.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PATRIOT</dt>
          <dd class="text">I come for Charles Town and Waxhaws.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My brother seen Tarleton kill prisoners.
            Burn houses. Shut his eyes to awful horror.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">TARLETON</dt>
          <dd class="text">Sedition is the death of society.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">WAGONEERS</dt>
          <dd class="text">Someone has to cook.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Men need their wounds dressed.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">They need a lot of things.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My husband is with the 6th Maryland.
            I had to bring the baby. The redcoats left us no town at all.</dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia in 1777,
          initially as a servant. But his long service—he was taken prisoner
          at Charleston and Camden, and fought through to Yorktown—indicates a
          brave professional soldier. There’s scant record of the black patriots
          at Camden; he speaks for many like him.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          LOYALISTS and BLACK LOYALISTS were common. Cornwallis freed any slave
          who would fight with him. For obvious reasons accounts of black loyalists
          are rare.
        </p>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool, was
          educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000 inheritance
          (£430,000 today) in a year. With the last he bought a cornetcy in the
          1st Dragoons, and found his way as a ruthless, even bloodthirsty soldier.
          His unit, known as ‘Tarleton’s Raiders,’ was finally defeated by Lauzun at
          Yorktown. He later exploited his two missing fingers (lost at Guilford Court
          House) for a seat in parliament.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
