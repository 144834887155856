<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 5</p>
      <p class="scene-title">Fear</p>
      <p class="scene-title">(Morning of the Battle)</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Primus, Pvt. Guilford Dudley, Lt. Col. Charles Porterfield,
          Pvt. Garrett Watts, Col. Otho Holland Williams, Gen. Horatio Gates</p>
        <p class="country">British</p>
        <p class="names">Lt. Col. Banastre Tarleton</p>
      </div>
      <div class="script-container">
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text" style="width: 250px;"><em>Crickets. Scattered gunfire.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Mist. Close air like cotton on a lamb.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Porterfield lives. But his leg hangs
            by a thread. Now first light comes I arrange his rescue.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Porterfield</dt>
          <dd class="text">Boys, don’t be disheartened. I’m a
            dead man, but don’t mind that.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">I’m three years a soldier. But these miserable
            militia in front, green, starved, barely fit to slaughter … </dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">Even Porterfield’s fame can’t save him.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Now Gates ride up fit for a cricket match:</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">Hold ‘til they come within six paces,
            then choose your man, fire, and bayonet the rest!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">But how do we do that?</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Around us the enemy come like wasps.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus (calls)</dt>
          <dd class="text">If I go to hell you’ll hang in chains with me –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">I whip my broken-down horse to the field –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">No man thinks, just move to kill ‘em before they kill us –</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Burst of gunfire.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">FIRE!!</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Patriot cannon. Then British cannon. Earth-shaking.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Smoke –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Smoke –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">LOOK THEM IN THE EYE!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts (coughs)</dt>
          <dd class="text">Black as night –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Farmers run like a river –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text margin-l"><em>Voices under: 'Halt!' 'Stand fast!'
            'Re-form!' Etc.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">I lived fifty more years and can’t say why I ran.
            I was caught in it. Still caught. Throw down my rifle and pick
            up a drum. As though it’s a shield. Run through the pines the
            twigs beat a rhythm. I cast it off and run like hell.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text margin-l"><em>Women/children scream, cry. Carts overturn.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneers</dt>
          <dd class="text">TAKE THE BABIES UP A TREE!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">AWAY! AWAY!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">IT’S NO GOOD LEAVING ORPHANS!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text margin-l"><em>Soldiers rush past. Women fight over a horse.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Gates is gone! Fled! Probably past Rugeley’s by now –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">Baron DeKalb is fallen.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Eight bayonets, three bullets –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">The German braver than so many of us.</dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia
          in 1777, initially as a servant. But his long service—he was
          taken prisoner at Charleston and Camden, and fought through to
          Yorktown—indicates a brave professional soldier. There’s scant
          record of the black patriots at Camden; he speaks for many like him.
        </p>
        <p class="bio-desc">
          GUILFORD DUDLEY (1756-1833) was a minuteman at Halifax, NC,
          from 1775. In 1780 he enlisted as a private in the dragoons
          of the North Carolina militia. He fought bravely at Camden
          but returned home after the defeat. Returning to service
          after the British invasion of North Carolina in 1781, he was
          quickly commissioned a major, then Lieutenant Colonel, then
          a colonel charged with suppressing loyalists.
        </p>
        <p class="bio-desc">
          OTHO HOLLAND WILLIAMS (1749-94) was the former clerk of Frederick County,
          MD, and a merchant. He volunteered for the Continental Army in 1775,
          and was commissioned a Captain in a rifle regiment. He saw action at
          Boston and Fort Washington, where he was taken prisoner and sent to
          New York. His rank afforded him limited parole—in the streets he
          sneered at his captors, and spied for the Continental Army. Caught,
          he was imprisoned. This broke his health; but on his release in 1778,
          he went straight to the southern campaign, where he distinguished himself
          with bravery and strategic sense. A favorite of George Washington,
          he retired as a Brigadier General.
        </p>
        <p class="bio-desc">
          HORATIO GATES (1727-1806) was born at Maldon, Essex, the son
          of a customs inspector. He purchased an Army commission, and
          served in the War of Austrian Succession and the Seven Years’ War.
          But he couldn’t get on, so he sold his commission and moved to Virginia,
          where he ingratiated himself with George Washington, who appointed him
          Adjutant General in 1775. Gates believed his own publicity as the
          ‘Hero of Saratoga’—he wasn’t, Burgoyne lost it. He tried to use his
          fame to supplant Washington, but it didn’t work. After Camden his
          political allies saved him from court martial. He never directly
          commanded troops again.
        </p>
        <p class="bio-desc">
          CHARLES PORTERFIELD (1750-80) was one of the first Virginia
          revolutionaries, fighting under Daniel Morgan from 1775.
          He distinguished himself during Benedict Arnold’s expedition
          to Quebec, at Saratoga, and elsewhere. Thomas Jefferson appointed
          him Lieutenant Colonel in 1779, commanding a regiment outfitted
          at his own expense.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool,
          was educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000
          inheritance (£430,000 today) in a year. With the last he bought
          a cornetcy in the 1st Dragoons, and found his way as a ruthless,
          even bloodthirsty soldier. His unit, known as ‘Tarleton’s Raiders,’
          was finally defeated by Lauzun at Yorktown. He later exploited his
          two missing fingers (lost at Guilford Court House) for a seat in parliament.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
