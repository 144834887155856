<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 7</p>
      <p class="scene-title">Loss & Resilience</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Gen. Horatio Gates, Primus, Pvt. Guilford Dudley,
          Col. Otho Holland Williams, Watts, Wagoneers, Hogan, Lt. Col. Charles Porterfield,
          Patriot Voices</p>
        <p class="country">British</p>
        <p class="names">Loyalists, Black Loyalists</p>
      </div>
      <div class="script-container">
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">With deepest distress, I am obliged to
            acquaint you with the total defeat of the troops under my command.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Lamb</dt>
          <dd class="text">Three years since Gates took me prisoner at Saratoga.
            Well, near two hundred fifty, but where I am it’s the same.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">A blessing to squash him, and drown the myth of his genius.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">I help a woman put her wagon right. She kissed me.
            I still feel her thumb on my cheek.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Black Loyalist</dt>
          <dd class="text">I died free. Nobody knows. But I do.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Busted my jaw on a rifle butt. Felt the rain rest
            of my life, but I fought at King’s Mountain, Guilford, Cowpens, Eutaw Springs –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">If their hope of victory rests solely in farmers . . .</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">At Guilford Court House I forage. Feed the front line.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">
            I heard Cornwallis surrendered at Yorktown. Except not. He couldn’t show his face.
          </dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Beat by these black hands.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Neither death nor defeat troubles me.
            I’m a soldier. Does a baker weep over loaves of bread?</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Gates</dt>
          <dd class="text">Washington saved me from court martial, but
            I never saw action again.<br><br>
            My bones are in New York, where I went to parties.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">In my grave I think of my mistress.
            She wrote poetry. That’s what endures.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">When I was a prisoner the British asked my trade.
            I said I’m a shopkeeper. I know to hate tyrants and bullies,
            and you’ll soon find out.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">I ran. But I came back.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneer</dt>
          <dd class="text">My baby forgets. Not me.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Watts</dt>
          <dd class="text">My granny said it’s the way of the world.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Here I am.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">In the air, fair enough. I’m in the dirt.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">British</dt>
          <dd class="text">Here.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Hello.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Porterfield</dt>
          <dd class="text">A man loses his leg, he looks for it.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Hogan</dt>
          <dd class="text">Never any peaches. Oh well.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Patriots</dt>
          <dd class="text">You’re on my chest.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">My back.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">British 1</dt>
          <dd class="text">Bit of my foot I left here.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Patriot</dt>
          <dd class="text">It’s fine.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">British 2</dt>
          <dd class="text">No pain now.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">We’re free to enjoy the company.</dd>
        </dl>
        <p class="scene-desc">End.</p>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          HORATIO GATES (1727-1806) was born at Maldon, Essex, the son of
          a customs inspector. He purchased an Army commission, and served
          in the War of Austrian Succession and the Seven Years’ War. But he
          couldn’t get on, so he sold his commission and moved to Virginia, where
          he ingratiated himself with George Washington, who appointed him Adjutant
          General in 1775. Gates believed his own publicity as the ‘Hero of
          Saratoga’—he wasn’t, Burgoyne lost it. He tried to use his fame to
          supplant Washington, but it didn’t work. After Camden his political
          allies saved him from court martial. He never directly commanded troops again.
        </p>
        <p class="bio-desc">
          GUILFORD DUDLEY (1756-1833) was a minuteman at Halifax, NC, from 1775.
          In 1780 he enlisted as a private in the dragoons of the North Carolina
          militia. He fought bravely at Camden but returned home after the defeat.
          Returning to service after the British invasion of North Carolina in 1781,
          he was quickly commissioned a major, then Lieutenant Colonel, then a
          colonel charged with suppressing loyalists.
        </p>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia
          in 1777, initially as a servant. But his long service—he
          was taken prisoner at Charleston and Camden, and fought through
          to Yorktown—indicates a brave professional soldier. There’s
          scant record of the black patriots at Camden; he speaks for many
          like him.
        </p>
        <p class="bio-desc">
          OTHO HOLLAND WILLIAMS (1749-94) was the former clerk of Frederick County,
          MD, and a merchant. He volunteered for the Continental Army in 1775,
          and was commissioned a Captain in a rifle regiment. He saw action at
          Boston and Fort Washington, where he was taken prisoner and sent to
          New York. His rank afforded him limited parole—in the streets he
          sneered at his captors, and spied for the Continental Army.
          Caught, he was imprisoned. This broke his health; but on his release
          in 1778, he went straight to the southern campaign, where he distinguished
          himself with bravery and strategic sense. A favorite of George Washington,
          he retired as a Brigadier General.
        </p>
        <p class="bio-desc">
          GARRETT WATTS (1756-1838) enlisted in the Perry County, NC,
          militia in 1779. He skirmished with Tories, but saw no significant
          action until Camden. He returned to fight at Guilford Court House.
        </p>
        <p class="bio-desc">
          HOGAN (d. 1780) While his unit was starving and hemmed in by floods,
          he and another man swam the Deep River to fetch unripe peaches.
          He lies on the Camden battlefield.
        </p>
        <p class="bio-desc">
          CHARLES PORTERFIELD (1750-80) was one of the first Virginia
          revolutionaries, fighting under Daniel Morgan from 1775.
          He distinguished himself during Benedict Arnold’s expedition
          to Quebec, at Saratoga, and elsewhere. Thomas Jefferson appointed
          him Lieutenant Colonel in 1779, commanding a regiment outfitted
          at his own expense.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          ROGER LAMB (1756-1830), one of 11 children born near Dublin port,
          wanted to follow his late brother into the Navy. His father forbade it,
          so Lamb ran off to the army when ruined by gambling debts. He was taken
          prisoner at Saratoga, but escaped. He was then captured at Yorktown,
          escaped, captured in Maryland, and fled to New York. In Ireland he became
          a teacher, and published an important autobiography.
        </p>
        <p class="bio-desc">
          LOYALISTS and BLACK LOYALISTS were common. Cornwallis freed
          any slave who would fight with him. For obvious reasons accounts
          of black loyalists are rare.
        </p>
        <p class="bio-desc">
          CHARLES CORNWALLIS (1738-1805), son of an Earl, was a veteran
          of the Seven Years’ War. He was second-in-command of British troops
          for most of the revolution, and their most gifted strategist.
          But Camden likely turned him cocky, as Saratoga did to Gates.
          He was later Lord Lieutenant of Ireland and Governor-General of India.
        </p>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool,
          was educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000
          inheritance (£430,000 today) in a year. With the last he bought
          a cornetcy in the 1st Dragoons, and found his way as a ruthless,
          even bloodthirsty soldier. His unit, known as ‘Tarleton’s Raiders,’
          was finally defeated by Lauzun at Yorktown. He later exploited his
          two missing fingers (lost at Guilford Court House) for a seat in parliament.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
