<template>
  <div id="app">
    <div v-if="isPopupShowing && route.path === '/'" class="overlay"></div>
    <PopUp :page="page" v-if="isPopupShowing && route.path === '/'"></PopUp>
    <div id="app" class="container">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import {
  reactive, toRefs, ref, computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import PopUp from '@/components/PopUp.vue';

export default {
  name: 'App',
  components: {
    PopUp,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const page = ref('homepage');
    const data = reactive({
      desktop: false,
    });
    const isPopupShowing = computed(() => store.state.popupActive);
    onMounted(() => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 400) {
        data.desktop = true;
      }
      window.onbeforeunload = () => {
        window.scrollTo(0, 0);
      };
    });
    return {
      ...toRefs(data),
      page,
      isPopupShowing,
      route,
    };
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: BeirutTextTrial;
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/BeirutTextTrial-Regular.otf') format("opentype");
}
@font-face {
  font-family: BeirutTextTrial;
  font-style: italic;
  font-weight: normal;
  src: url('/assets/fonts/BeirutTextTrial-RegularItalic.otf') format("opentype");
}
@font-face {
  font-family: BeirutDisplayTrial;
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/BeirutDisplayTrial-Bold.otf") format("opentype");
}
@font-face {
  font-family: BeirutTextTrial;
  font-style: italic;
  font-weight: bold;
  src: url('/assets/fonts/BeirutTextTrial-BoldItalic.otf') format("opentype");
}
@font-face {
  font-family: BeirutDisplayTrial;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/BeirutDisplayTrial-Regular.otf") format("opentype");
}
@font-face {
  font-family: BeirutDisplayTrial;
  font-style: italic;
  font-weight: normal;
  src: url("assets/fonts/BeirutDisplayTrial-RegularItalic.otf") format("opentype");
}
@font-face {
  font-family: Karla-Medium;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/Karla-VariableFont_wght.ttf") format("opentype");
}
 html, body {
    overscroll-behavior-x: none;
}
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #f3cfb7;
}
#app {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 10px 8px 9px 5px rgba(145,145,145,0);
  box-shadow: 0px 10px 13px -7px #000000, 10px 8px 9px 5px rgba(145,145,145,0);
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    background: rgba(0, 0, 0, 0.548);
  }
  .container {
    position: relative;
    text-align: center;
    color: #2c3e50;
    margin-top: 0;
    background-color: #f3cfb7 !important;
  }
}
.no-scroll {
  overflow: hidden;
}
</style>
