<template>
  <div class="interstitial-container">
    <SceneHeader></SceneHeader>
    <img class="interstitial-img" src="@/assets/media/interstitial_1.jpg"
      rel="preload" alt="interstitial image logo">
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';

export default {
  components: {
    SceneHeader,
  },
  setup() {
    const data = reactive({
      imgUrl: null,
    });
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.interstitial-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .interstitial-img {
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
