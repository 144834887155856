<template>
  <div class="footer">
    <div class="logos">
      <img class="logo" src="@/assets/media/Camden_ABT_Green.png" alt="Camden ABT Logo">
      <img class="logo" style="width: 75px;" src="@/assets/media/SCBPT_CircleOnly.png"
        alt="SCBPT LOGO">
    </div>
    <p>&copy;2022 American Battlefield Trust<br>
    and South Carolina Battleground Preservation Trust</p>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';

export default {
  setup() {
    const data = reactive({
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f3cfb7;
    width: 100%;
    height: 300px;
    font-family: Karla-Medium;
    font-size: 10px;
    color: rgba(11,83,64,255);
    margin-bottom: 20px;
    .logos {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
      width: 70px;
      height: 75px;
      margin-top: -20%;
      margin-bottom: 20px;
      margin-left: 20px;
    }
    }
  }
</style>
