<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 2</p>
      <p class="scene-title">The Night Battle</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">
          Primus, Col. Otho Holland Williams, Pvt. Guilford Dudley, Lt. Col. Charles Porterfield
        </p>
        <p class="country">British</p>
        <p class="names">Lt. Col. Banastre Tarleton, British Voices</p>
      </div>
      <div class="script-container">
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Creaking leather. Horses. Wildlife.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Profound silence. The rebels two to one against
            but our minds keen as blades.<br><br> Now past Saunders’ Creek, over
            the crest –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">British 1</dt>
          <dd class="text">CHARLES TOWN!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">They took me prisoner there.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Friends will say God Save the King –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PATRIOTS</dt>
          <dd class="text">WHO’S THERE? WHAT’S YOUR UNIT?!</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>A moment, then a burst of patriot pistols</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">TARLETON / OTHERS</dt>
          <dd class="text">CHARGE!! CHARGE!! CHARGE!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">WILLIAMS</dt>
          <dd class="text">COME ON, LADS! SHOW THEM HOW MARYLAND FIGHTS!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">DUDLEY</dt>
          <dd class="text">Colonel Porterfield wants their flank –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PORTERFIELD</dt>
          <dd class="text">DOUBLE QUICK! HALT, FRONT! PRESENT! FIRE!!</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Strong fire from both sides.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">TARLETON</dt>
          <dd class="text">RETREAT! FALL BACK!!</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Scattered fires, shouts continue under–</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PATRIOTS (overlap)</dt>
          <dd class="text">Reform your line!<br><br>
            Infantry coming –<br><br>
            PORTERFIELD!!<br><br>
            Colonel’s down!!<br><br>
            Blood for blood, boys!!
          </dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PRIMUS</dt>
          <dd class="text">Easy!! We don’t want it at night –</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text">PORTERFIELD <em>in shock, his leg shattered.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PORTERFIELD</dt>
          <dd class="text">Don’t leave me, Dudley. Don’t leave. Don’t leave.
            Don’t, don’t, don’t leave.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Etc.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">DUDLEY</dt>
          <dd class="text">Listen. You put them on the run.<br><br>
            I’ll carry you off or die with you. Understand?
          </dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">PORTERFIELD</dt>
          <dd class="text">Good lad.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">DUDLEY</dt>
          <dd class="text">SURGEON!!</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>He echoes in silence.</em></dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia in 1777,
          initially as a servant. But his long service—he was taken prisoner
          at Charleston and Camden, and fought through to Yorktown—indicates a
          brave professional soldier. There’s scant record of the black patriots
          at Camden; he speaks for many like him.
        </p>
        <p class="bio-desc">
          OTHO HOLLAND WILLIAMS (1749-94) was the former clerk of Frederick County,
          MD, and a merchant. He volunteered for the Continental Army in 1775, and
          was commissioned a Captain in a rifle regiment. He saw action at Boston
          and Fort Washington, where he was taken prisoner and sent to New York.
          His rank afforded him limited parole—in the streets he sneered at his captors,
          and spied for the Continental Army. Caught, he was imprisoned. This broke his
          health; but on his release in 1778, he went straight to the southern campaign,
          where he distinguished himself with bravery and strategic sense. A favorite of
          George Washington, he retired as a Brigadier General.
        </p>
        <p class="bio-desc">
          GUILFORD DUDLEY (1756-1833) was a minuteman at Halifax, NC,
          from 1775. In 1780 he enlisted as a private in the dragoons of the
          North Carolina militia. He fought bravely at Camden but returned
          home after the defeat. Returning to service after the British invasion
          of North Carolina in 1781, he was quickly commissioned a major, then
          Lieutenant Colonel, then a colonel charged with suppressing loyalists.
        </p>
        <p class="bio-desc">
          CHARLES PORTERFIELD (1750-80) was one of the first Virginia revolutionaries,
          fighting under Daniel Morgan from 1775. He distinguished himself during
          Benedict Arnold’s expedition to Quebec, at Saratoga, and elsewhere. Thomas
          Jefferson appointed him Lieutenant Colonel in 1779, commanding a regiment
          outfitted at his own expense.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool, was
          educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000 inheritance
          (£430,000 today) in a year. With the last he bought a cornetcy in the
          1st Dragoons, and found his way as a ruthless, even bloodthirsty soldier.
          His unit, known as ‘Tarleton’s Raiders,’ was finally defeated by Lauzun at
          Yorktown. He later exploited his two missing fingers (lost at Guilford Court
          House) for a seat in parliament.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
