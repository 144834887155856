import { createApp, h } from 'vue';
import './app.scss';
import App from './App.vue';
import store from './store';
import router from './router';
import 'hammerjs';

const myApp = createApp({
  render: () => h(App),
});

myApp
  .use(router)
  .use(store);

myApp.mount('#app');
