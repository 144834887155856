<template>
  <div class="credits-container">
    <p class="title">Credits</p>
    <div class="credits" v-for="(item, idx) in Object.keys(credits)" :key="idx">
      <p class="company">{{item}}</p>
      <div class="e-r-container" v-for="(employee, i) in credits[item].employees" :key="i">
        <p class="employee">{{ employee }}</p>
        <p class="role">{{credits[item].roles[i]}}</p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import Footer from '@/components/Footer.vue';
import credits from '@/assets/credits/credits';

export default {
  name: 'Credits',
  components: {
    Footer,
  },
  setup() {
    const data = reactive({});
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      ...toRefs(data),
      credits,
    };
  },
};
</script>

<style lang="scss" scoped>
  .credits-container {
    position: relative;
    top: 10%;
    width: 100%;
    color: rgba(11,83,64,255);
    overflow-y: scroll;
    .title {
      font-size: 52px;
    }
    .credits {
      width: 85%;
      margin: auto;
      border-top: 1px solid rgba(11,83,64,255);
      border-bottom: 1px solid rgba(11,83,64,255);
      font-size: 16px;
      padding: 20px 0px 40px 0px;
      .e-r-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .role {
        margin-bottom: 0;
        font-family: BeirutDisplayTrial;
        text-align: right;
      }
      .employee {
        width: 150px;
        margin-bottom: 0;
        font-family: BeirutDisplayTrial;
        text-align: left;
      }
      .company {
        margin: 0;
        font-family: Karla-Medium;
      }
    }
    .footer {
      margin-top: 0px;
    }
  }
</style>
