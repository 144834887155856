import { createStore } from 'vuex';

export default createStore({
  state: {
    popupActive: false,
    isAudioPlaying: false,
    scene: 'intro',
    items: [
      'Introduction',
      'InterstitialOne',
      'SceneOne',
      'InterstitialTwo',
      'SceneTwo',
      'InterstitialThree',
      'SceneThree',
      'InterstitialFour',
      'SceneFour',
      'InterstitialFive',
      'SceneFive',
      'InterstitialSix',
      'SceneSix',
      'InterstitialSeven',
      'SceneSeven',
      'Credits',
    ],
    currentIndex: 0,
    upcomingIndex: 0,
    renderedItems: ['credits', 'intro', '1'],
  },
  mutations: {
    SET_POP_UP(state, value) {
      state.popupActive = value;
    },
    SET_SCENE(state, value) {
      state.scene = value;
    },
    SET_CURRENT_INDEX(state, value) {
      state.currentIndex = value;
    },
    SET_AUDIO(state, value) {
      state.isAudioPlaying = value;
    },
  },
  actions: {
    setPopup({ commit }, value) {
      commit('SET_POP_UP', value);
    },
    setScene({ commit }, value) {
      commit('SET_SCENE', value);
    },
    setCurrentIndex({ commit }, value) {
      commit('SET_CURRENT_INDEX', value);
    },
    setAudio({ commit }, value) {
      commit('SET_AUDIO', value);
    },
  },
  modules: {
  },
});
