<template>
  <div class="menu-container">
    <div class="menu">
      <label @click="toggleNav"  class="menu-btn" for="toggle">
        <span v-if="navActive">
          <i class="bi bi-x menu-btn-text"></i>
        </span>
        <span v-else class="menu-btn-text m-top">=</span>
      </label>
    </div>
    <input ref="toggleSwitch" type="checkbox" id="toggle" name="toggle">
    <div class="verticalNav">
        <div @click="onGoToActOne" class="nav-link-1">
          <div class="content">
            <p class="act-num">ACT 1</p>
            <h1 v-if="!listActive" class="act-header">On The Road</h1>
            <p v-if="!listActive" class="act-desc">Hop in your car, buckle up, and press play.</p>
          </div>
        </div>
        <div class="nav-link-2">
          <div class="content">
            <div @click="onGoToActTwo" class="content">
              <p class="act-num" :style="{ color: actTwoColor }">ACT 2</p>
              <h1 v-if="!listActive" class="act-header">On The Battlefield</h1>
            </div>
            <p v-if="!listActive" class="act-desc">
              Find the monument, listen to the stories from the
              people who were at the Battle of Camden
            </p>
            <ul ref="sceneList" class="list-closed">
              <li v-for="(item, idx) in list" :key="idx" class="list-item">
                <a v-if="item !== 'Interstitial'" @click="onNavigateToScene(idx)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </div>
        <i @click="toggleList"
          :class="listActive ? 'fas fa-chevron-down fa-lg open'
            : 'fas fa-chevron-down fa-lg close'"></i>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      toggleSwitch: null,
      sceneList: null,
      sceneListChildren: null,
      navActive: false,
      listActive: false,
      actTwoColor: '#ec5f56',
      list: [
        'Introduction',
        'Interstitial',
        'Scene 1',
        'Interstitial',
        'Scene 2',
        'Interstitial',
        'Scene 3',
        'Interstitial',
        'Scene 4',
        'Interstitial',
        'Scene 5',
        'Interstitial',
        'Scene 6',
        'Interstitial',
        'Scene 7',
        'Credits',
      ],
    });
    // Variable storing theme color
    const BASE_COLOR_RED = '#ec5f56';

    const toggleList = () => {
      data.listActive = !data.listActive;
      if (data.listActive) {
        data.actTwoColor = '#fff';
        data.sceneList.classList.add('list-opened');
      } else {
        data.actTwoColor = BASE_COLOR_RED;
        data.sceneList.classList.remove('list-opened');
      }
    };

    const toggleNav = () => {
      data.navActive = !data.navActive;
      data.listActive = false;
      if (data.listActive) {
        data.actTwoColor = '#fff';
        data.sceneList.classList.add('list-opened');
      } else {
        data.actTwoColor = BASE_COLOR_RED;
        data.sceneList.classList.remove('list-opened');
      }
    };

    const onNavigateToScene = (value) => {
      toggleList();
      data.navActive = !data.navActive;
      data.toggleSwitch.checked = !data.toggleSwitch.checked;
      store.dispatch('setCurrentIndex', value);
      store.dispatch('setAudio', false);
      router.push({ path: '/act2' });
    };

    const onGoToActTwo = () => {
      const introIndex = 0;
      toggleNav();
      data.actTwoColor = BASE_COLOR_RED;
      data.toggleSwitch.checked = !data.toggleSwitch.checked;
      store.dispatch('setCurrentIndex', introIndex);
      store.dispatch('setAudio', false);
      router.push({ path: '/act2' });
      window.scrollTo(0, 0);
    };

    const onGoToActOne = () => {
      toggleNav();
      data.actTwoColor = BASE_COLOR_RED;
      data.toggleSwitch.checked = !data.toggleSwitch.checked;
      store.dispatch('setAudio', false);
      router.push({ path: '/act1' });
    };

    return {
      ...toRefs(data),
      toggleList,
      toggleNav,
      onNavigateToScene,
      onGoToActTwo,
      onGoToActOne,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  position: relative;
  z-index: 1;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  /* MENU BAR CSS */
  .menu {
      position: absolute;
      display: flex;
      top: 3%;
      left: 83%;
      width: 100vw;
      max-width: 500px;
      background-color: transparent;
      z-index: 2;
      .menu-btn {
        position: fixed;
        top: 3%;
        cursor: pointer;
        display: flex;
        height: 50px;
        width: 50px;
        background-color: #fff;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        .menu-btn-text {
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(236,95,86,255);
          font-size: 50px;
        }
        .m-top {
          margin-bottom: 7px;
        }
      }
  }
  /* VERTICAL NAV CSS */
  .verticalNav {
    position: fixed;
    margin: 0;
    width: 100%;
    max-width: 500px;
    top: -2250px;
    height: 100vh;
    padding-top: 70px;
    background-color: rgba(11,83,64,255);
    transition: top 700ms cubic-bezier(0.17, 0.3, 0.03, 0.94);
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: BeirutDisplayTrial;
    font-style: normal;
    overflow-x: hidden;
    .nav-link-1, .nav-link-2 {
      color: rgba(236,95,86,255);
      text-decoration: none;
      font-family: BeirutDisplayTrial;
      font-style: normal;
      font-weight: normal;
      padding: 15px 0px;
      cursor: pointer;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        text-decoration: none;
        color: rgba(236,95,86,255);
        .list-closed {
          overflow: hidden;
          list-style-type: none !important;
          font-size: 20px;
          margin-top: 5px;
          margin-bottom: 0px;
          max-height: 0;
          padding: 0;
          transition-property: all;
          transition-duration: .5s;
          transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          .list-item {
            margin: 10px;
          }
        }
        .list-opened {
          max-height: 500px;
          transition-property: all;
          transition-duration: 2s;
          transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        }
        .act-num, .act-desc {
          font-size: 20px;
          margin: 0;
          width: 300px;
        }
        .act-desc {
          margin-top: 10px;
        }
        .act-header {
          width: 200px;
          font-size: 30px;
          margin: 0;
        }
      }
    }
    .nav-link-1 {
      border-bottom: 2px solid rgba(236,95,86,255);
    }
    .open {
      color: #ec5f56;
      margin-top: 0;
      cursor: pointer;
      transform: rotate( -180deg );
      transition: transform 300ms ease;
    }
    .close {
      color: #ec5f56;
      margin-top: 0;
      cursor: pointer;
      transition: transform 300ms ease;
    }
  }
  #toggle {
      position: absolute;
      visibility: hidden;
  }
  #toggle:checked ~ .verticalNav {
      top: -20px;
  }
}

</style>
