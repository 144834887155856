<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 1</p>
      <p class="scene-title">Marching at Night</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Primus, Pvt. Guilford Dudley, Wagoneers</p>
        <p class="country">British</p>
        <p class="names">Lt. Col. Banastre Tarleton</p>
      </div>
      <div class="script-container">
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text" style="width: 300px;">
            <em>Hooves muffled on sand. Wagon and cannon wheels.
            Feet on pine needles. Breath</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Full moon like midday.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Pine plains bright in the gaps.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Swamps to either side, and fords in the creek.
            A trifle for the professional soldier. But these ragged rebel farmers –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Even the regulars puking their guts out.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text" style="margin-left: -5%;"><em>Sound</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Tanked up on molasses for want of food. Moaning sick, eight miles.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">General Gates wants higher ground.
            Anyone makes noise will be shot on his order.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">He can’t shoot everybody.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text" style="margin-left: -5%;"><em>Baby cries</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneer</dt>
          <dd class="text">For god’s sake cover his mouth!
            Or it won’t just be the men blown to hell!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">They are considerable. But the King’s force will
            march north to engage them at Rugeley’s Mill, and sweep them into the swamp.</dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia in 1777,
          initially as a servant. But his long service—he was taken prisoner at
          Charleston and Camden, and fought through to Yorktown—indicates a brave
          professional soldier. There’s scant record of the black patriots at Camden;
          he speaks for many like him.
        </p>
        <p class="bio-desc">
          GUILFORD DUDLEY (1756-1833) was a
          minuteman at Halifax, NC, from 1775. In 1780 he enlisted as a private
          in the dragoons of the North Carolina militia. He fought bravely at Camden
          but returned home after the defeat. Returning to service after the British
          invasion of North Carolina in 1781, he was quickly commissioned a major,
          then Lieutenant Colonel, then a colonel charged with suppressing loyalists.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool, was
          educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000 inheritance
          (£430,000 today) in a year. With the last he bought a cornetcy in the
          1st Dragoons, and found his way as a ruthless, even bloodthirsty soldier.
          His unit, known as ‘Tarleton’s Raiders,’ was finally defeated by Lauzun at
          Yorktown. He later exploited his two missing fingers (lost at Guilford Court
          House) for a seat in parliament.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
  margin-top: 0px;
}
</style>
