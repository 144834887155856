<template>
  <div :style="isPopupShowing || currentItem.id === 'Introduction' || currentItem.id === 'Credits'
    ? 'overflow: hidden' : 'overflow: none'"
    ref="act2" id="act-2-container">
    <Navbar class="navbar"></Navbar>
    <div ref="renderedItemsContainer" id="rendered-items-flexbox" :class="transitionClass"
      :style="{transform: transformStyle}">
      <component v-for="item in renderedItems" :key="item.key"
        :id="item.key" :is="item.id"
        :class="currentItem.id.includes('Interstitial') ? 'rendered-item-i' : 'rendered-item'">
      </component>
    </div>
    <div v-if="windowWidth > MAX_SCREEN_WIDTH" class="arrows-container">
      <i @click="previous" class="fas fa-chevron-left fa-lg left-arrow"></i>
      <i @click="next" class="fas fa-chevron-right fa-lg right-arrow"></i>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  toRefs,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import Hammer from 'hammerjs';
import _ from 'lodash';
import SceneHeader from '@/components/SceneHeader.vue';
import Navbar from '@/components/Navbar.vue';
import Introduction from '@/components/actTwo/Introduction.vue';
/* --- MAP MIGHT BE ADDED IN THE FUTURE --- */
// import Map from '@/components/actTwo/Map.vue';
import Credits from '@/components/actTwo/Credits.vue';
import InterstitialOne from '@/components/actTwo/InterstitialOne.vue';
import InterstitialTwo from '@/components/actTwo/InterstitialTwo.vue';
import InterstitialThree from '@/components/actTwo/InterstitialThree.vue';
import InterstitialFour from '@/components/actTwo/InterstitialFour.vue';
import InterstitialFive from '@/components/actTwo/InterstitialFive.vue';
import InterstitialSix from '@/components/actTwo/InterstitialSix.vue';
import InterstitialSeven from '@/components/actTwo/InterstitialSeven.vue';
import SceneOne from '@/components/actTwo/scenes/SceneOne.vue';
import SceneTwo from '@/components/actTwo/scenes/SceneTwo.vue';
import SceneThree from '@/components/actTwo/scenes/SceneThree.vue';
import SceneFour from '@/components/actTwo/scenes/SceneFour.vue';
import SceneFive from '@/components/actTwo/scenes/SceneFive.vue';
import SceneSix from '@/components/actTwo/scenes/SceneSix.vue';
import SceneSeven from '@/components/actTwo/scenes/SceneSeven.vue';

export default {
  name: 'Act2',
  components: {
    SceneHeader,
    Navbar,
    Introduction,
    Credits,
    InterstitialOne,
    InterstitialTwo,
    InterstitialThree,
    InterstitialFour,
    InterstitialFive,
    InterstitialSix,
    InterstitialSeven,
    SceneOne,
    SceneTwo,
    SceneThree,
    SceneFour,
    SceneFive,
    SceneSix,
    SceneSeven,
  },
  setup() {
    // PROBLEM: Transition: Transform doesn't work as expected on mobile chrome iOS
    // POSSIBLE SOLUTIONS:
    // 1. Restart browser - I can add a note in the popup for users who experience this to
    //    restart their mobile broswer app
    // 2. Switch transition to keyframes transition
    // 3. cleanup code

    const MAX_SCREEN_WIDTH = 1000;

    const store = useStore();
    const windowWidth = ref(null);

    const data = reactive({
      act2: null,
      isInfiniteLoop: true,
      prefersReducedMotion: false,
      translateX: 0,
      maxTranslateX: 10,
      transformStyle: 'translateX(0)',
      transitionClass: 'transition-initial',
      isTransitioning: false,
      renderedItemsContainer: null,
    });
    // const width = computed(() => windowWidth.value);
    const onResize = () => {
      windowWidth.value = document.documentElement.clientWidth;
    };
    const infoItems = computed(() => {
      let arr = [...store.state.items];
      if (arr.length === 2) {
        arr = [...arr, ...arr];
      }
      return arr.map((id, index) => ({
        id,
        key: `${id}-${index}`,
      }));
    });

    const isPopupShowing = computed(() => store.state.popupActive);
    const renderedItems = computed(() => {
      const { currentIndex: i } = store.state;
      if (infoItems.value.length === 1) {
        return [infoItems.value[0]];
      }

      const lastIndex = infoItems.value.length - 1;
      const prevIndex = i === 0 ? lastIndex : i - 1;
      const nextIndex = i === lastIndex ? 0 : i + 1;

      return [infoItems.value[prevIndex], infoItems.value[i], infoItems.value[nextIndex]];
    });

    const currentItem = computed(() => {
      const { currentIndex: i } = store.state;
      if (infoItems.value.length === 1) {
        return [infoItems.value[0]];
      }
      return infoItems.value[i];
    });

    const previous = _.debounce(
      () => {
        const { currentIndex, items } = store.state;

        data.transitionClass = 'transition-item';
        // check width of window
        if (windowWidth.value > MAX_SCREEN_WIDTH) {
          // set transform to 500px
          data.transformStyle = 'translateX(500px)';
        } else {
          // otherwise the whole page
          data.transformStyle = 'translateX(100%)';
        }
        // calculate previous index
        // if currentIndex is equal to 0 then previous is last index
        // otherwise previous index is equal to current - 1
        const prevIndex = (currentIndex === 0) ? items.length - 1 : currentIndex - 1;
        store.state.upcomingIndex = prevIndex;
      }, 100, { leading: true, trailing: false },
    );

    const next = _.debounce(
      () => {
        const { currentIndex, items } = store.state;

        data.transitionClass = 'transition-item';
        // check width of window
        if (windowWidth.value > MAX_SCREEN_WIDTH) {
          // set transform to -500px
          data.transformStyle = 'translateX(-500px)';
        } else {
          // otherwise the whole page
          data.transformStyle = 'translateX(-100%)';
        }
        // calculate previous index
        // if currentIndex is equal to last index then next is 0
        // otherwise next index is equal to current + 1
        const nextIndex = (currentIndex === items.length - 1) ? 0 : currentIndex + 1;
        store.state.upcomingIndex = nextIndex;
      }, 100, { leading: true, trailing: false },
    );

    const handleGestureMove = (deltaX) => {
      const { maxTranslateX } = data;
      if (Math.abs(deltaX) > Math.abs(maxTranslateX)) {
        data.maxTranslateX = deltaX;
      }
      data.translateX = deltaX;
      data.transitionClass = 'transition-initial';
      data.transformStyle = `translateX(${deltaX}px)`;
    };

    const handleGestureEnd = () => {
      const { translateX, maxTranslateX } = data;

      if (Math.abs(translateX) - Math.abs(maxTranslateX) < -1) {
        data.transitionClass = 'transition-item';
        data.transformStyle = 'translateX(0)';
      } else if (translateX > 0 && !isPopupShowing.value) {
        previous();
        window.scrollTo(0, 0);
      } else if (translateX < 0 && !isPopupShowing.value) {
        next();
        window.scrollTo(0, 0);
      }
    };

    const resetTranslate = () => {
      data.isTransitioning = false;
      data.transitionClass = 'transition-initial';
      data.transformStyle = 'translateX(0)';
      data.translateX = 0;
      data.maxTranslateX = 10;
    };

    const updateCurrentItem = () => {
      store.state.currentIndex = store.state.upcomingIndex;
      resetTranslate();
    };

    const handleTouchEvents = (e) => {
      // conditionals in place checking whether a user is still
      // transitioning between scenes or if they have finished swiping
      const { isTransitioning, translateX } = data;
      const { deltaX, deltaY, isFinal } = e;
      if (isTransitioning) {
        return;
      }
      // if the absolute value of e.deltaX is in between -1 and 8
      // and translateX is not truthy then return
      if (
        (Math.abs(deltaX) < 8 || Math.abs(deltaY) - Math.abs(deltaX) > -1)
        && !translateX
      ) {
        return;
      }

      if (isFinal && !isPopupShowing.value) {
        handleGestureEnd(deltaX);
      }
      if (!isFinal && !isPopupShowing.value) {
        handleGestureMove(deltaX);
      }
    };

    onMounted(() => {
      window.scrollTo(0, 0);

      if (store.state.currentIndex === 0) {
        store.dispatch('setPopup', true);
      }
      windowWidth.value = document.documentElement.clientWidth;
      window.addEventListener('resize', onResize);

      const touchContainer = data.act2;
      const hammer = new Hammer.Manager(touchContainer, {
        recognizers: [
          [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }],
          [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
        ],
      });
      hammer.on('pan swipe', handleTouchEvents);
      const itemsContainer = data.renderedItemsContainer;
      itemsContainer.addEventListener('transitionstart', (e) => {
        if (e.target === itemsContainer) {
          data.isTransitioning = true;
        }
      });
      itemsContainer.addEventListener('transitionend', (e) => {
        if (e.target === itemsContainer) {
          updateCurrentItem();
        }
      });
    });
    onUnmounted(() => window.removeEventListener('resize', onResize));
    return {
      ...toRefs(data),
      windowWidth,
      isPopupShowing,
      onResize,
      infoItems,
      renderedItems,
      currentItem,
      handleTouchEvents,
      handleGestureMove,
      handleGestureEnd,
      next,
      previous,
      resetTranslate,
      updateCurrentItem,
      MAX_SCREEN_WIDTH,
    };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#act-2-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  .transition-initial {
    transition-property: transform;
    transition: transform 0s ease-in-out;
    -webkit-transition: transform 0s ease-in-out;
    -ms-transition: transform 0s ease-in-out;
    -o-transition: transform 0s ease-in-out;
    -moz-transition: transform 0s ease-in-out;
  }
  .transition-item {
    transition: transform 350ms cubic-bezier(.67,.37,.24,.63);
    -webkit-transition: transform 350ms cubic-bezier(.67,.37,.24,.63);
    -ms-transition: transform 350ms cubic-bezier(.67,.37,.24,.63);
    -o-transition: transform 350ms cubic-bezier(.67,.37,.24,.63);
    -moz-transition: transform 350ms cubic-bezier(.67,.37,.24,.63);
  }
  #rendered-items-flexbox {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    .rendered-item-i {
      overflow: hidden !important;
      touch-action: none !important;
      height: 100%;
      min-height: 500px;
      min-width: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .rendered-item {
      height: 100%;
      min-height: 500px;
      min-width: 100%;
      width: 100%;
      box-sizing: border-box;
      touch-action: auto !important;
    }
  }
  .arrows-container {
    position: absolute;
    top: 50%;
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: rgba(11,83,64,255);
    .left-arrow, .right-arrow {
      font-size: 70px;
      cursor: pointer;
    }
    .left-arrow {
      position: absolute;
      top: 0;
      left: -40%;
    }
    .right-arrow {
      position: absolute;
      top: 0;
      left: 130%;
    }
  }
}
</style>
