<template>
  <div class="intro-container">
    <div v-if="isPopupShowing" class="overlay"></div>
    <PopUp :page="page" v-if="isPopupShowing"></PopUp>
    <div @click="onGoHome" class="home-link">
      <p class='header'>Voices of Camden</p>
    </div>
    <div class="acts-container">
      <p class="act-1-header">Act 2</p>
      <h1 class="act-1-title">On the Battlefield</h1>
      <p class="header-description">
        Awaiting you at the Camden Battlefield is an interactive monument and immersive audio
        experience just off the path from the parking lot.
        This immersive audio play connects you to the dramatic tensions, diversity of voices,
        and untold perspectives that have never been brought to life.
        These voices want to be heard. They need to unburden themselves.<br><br>
        The Battle of Camden was fought in 1780. It was one of several devastating
        defeats suffered by the Americans in the early stages of the British
        military offensive in the South.
      </p>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PopUp from '@/components/PopUp.vue';

export default {
  components: {
    PopUp,
  },
  name: 'Introduction',
  setup() {
    const router = useRouter();
    const store = useStore();
    const page = ref('introduction');
    const data = reactive({
      windowWidth: null,
    });

    const isPopupShowing = computed(() => store.state.popupActive);

    const onGoHome = () => {
      router.push({ path: '/' });
    };
    const onResize = () => {
      data.windowWidth = window.innerWidth;
    };

    watch(() => store.state.currentIndex, (current) => {
      if (current === 0) {
        store.dispatch('setPopup', true);
      }
    });
    onMounted(() => {
      data.windowWidth = window.innerWidth;
      window.addEventListener('resize', onResize);
    });
    onUnmounted(() => window.removeEventListener('resize', onResize));
    const width = computed(() => data.windowWidth);
    return {
      ...toRefs(data),
      isPopupShowing,
      page,
      onGoHome,
      onResize,
      width,
    };
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 1000px !important;
}
.intro-container {
  margin-top: 0px;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  background-color: rgba(11,83,64,255);
  overflow-y: scroll;
  .home-link {
    position: relative;
    top: 20px;
    left: 10px;
    text-decoration: none;
    width: 200px;
    z-index: 3;
    .header {
      margin-top: 10%;
      margin-left: 5%;
      font-size: 18px;
      font-weight: bold;
      color: #f3cfb7;
      text-align: start;
      cursor: pointer;
    }
  }
  .acts-container {
    position: relative;
    background-color: rgba(11,83,64,255);
    color: rgba(236,95,86,255);
    padding: 50px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .act-1-header {
      font-size: 24px;
    }
    .act-1-title {
      margin: 0;
      font-size: 44px;
      width: 250px;
      text-align: center;
      margin-bottom: 20px;
    }
    .header-description {
      width: 330px;
      font-weight: normal;
      font-size: 24px;
      margin: 0;
      margin-bottom: 60px;
    }
    .swipe-left-arrow {
      position: relative;
      top: 25px;
    }
    .swipe-right-arrow {
      position: relative;
      bottom: 54px;
    }
    .hand-icon {
      margin-top: 5px;
      width: 38px;
      height: 50px;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    background: rgba(0, 0, 0, 0.548);
    overflow: hidden;
  }
}
</style>
