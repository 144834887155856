<template>
  <div class="container">
    <Navbar class="navbar"></Navbar>
    <div class="header">
      <p class="title">Voices of Camden</p>
      <p class="sub-title">An Immersive Audio Play</p>
      <p class="header-description">Hop in your car, buckle up, and press play.
         We'll be following the route of the British.
         They started their journey here too. Let's see
         where they ended up.
      </p>
    </div>
    <div class="svg">
      <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1700 100">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7"
          refX="0" refY="3.5" fill="rgba(255, 255, 255, 0.534)" orient="auto">
            <polygon points="0 0, 3 3.5, 0 7" />
          </marker>
        </defs>
        <line x1="800" y1="50" x2="450" y2="50" stroke="rgba(255, 255, 255, 0.534)"
        stroke-dasharray="25"
        stroke-width="8" marker-end="url(#arrowhead)" />
      </svg>
    </div>
    <div class="geo_uri_container">
      <p class="destination_title">Set Your Destination</p>
      <img class="icon" src="@/assets/media/Camden_Point_Marker.png" alt="location marker icon">
      <a id="link" href="http://maps.google.com/?daddr=1698+Flat+Rock+Rd+Camden+SC,+29020&dirflg=d&t=m"
        class="geo_uri_link">
        Camden Battlefield and Longleaf Pine Preserve 1698 Flat Rock Rd, Camden, SC 29020
      </a>
    </div>
    <Popper id="popper" content="Address copied!">
      <button id="copy-btn" @click="onCopyAddress" class="copy-btn">Copy Address</button>
    </Popper>
    <div class="svg">
      <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1700 100">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7"
          refX="0" refY="3.5" fill="rgba(255, 255, 255, 0.534)" orient="auto">
            <polygon points="0 0, 3 3.5, 0 7" />
          </marker>
        </defs>
        <line x1="800" y1="50" x2="450" y2="50" stroke="rgba(255, 255, 255, 0.534)"
        stroke-dasharray="25"
        stroke-width="8" marker-end="url(#arrowhead)" />
      </svg>
    </div>
    <div class="geo_uri_container">
      <p class="geo-desc">When you know where you're headed, press play and start driving.</p>
    </div>
    <div class="acts-container">
      <p @click="onNavigateToActOne" class="act-1-header">Act 1</p>
      <h1 @click="onNavigateToActOne" class="act-1-title">On the Road</h1>
      <div @click="(e) => onToggleAudioPlay(e)" class="play-btn">
        <i v-if="!$store.state.isAudioPlaying" class="bi bi-play-fill play-pause-icon"></i>
        <i v-else class="bi bi-pause play-pause-icon margin-right"></i>
      </div>
      <p class="act-1-header">Use this on your drive.</p>
      <hr class="divider">
      <div @click="onNavigateToActTwo">
        <p class="act-1-header">Act 2</p>
        <h1 class="act-1-title">On the Battlefield</h1>
      </div>
      <svg class="swipe-arrow" viewBox="0 0 300 100">
        <defs fill="rgba(236,95,86,255)">
          <marker id="end" refX="0" refY="25" markerUnits="userSpaceOnUse"
            markerWidth="150" markerHeight="150" orient="auto">
              <path id="arrowhead" d="M 0 0 L 20 25 L 0 50z" fill="rgba(236,95,86,255)"
              stroke-linejoin="round"/>
          </marker>
        </defs>
        <line x1="50" y1="50" x2="225" y2="50" stroke="rgba(236,95,86,255)"
        stroke-dasharray="15"
        stroke-width="8" marker-end="url(#end)"/>
      </svg>
      <p class="act-1-header">Use this when you arrive.</p>
    </div>
    <Footer></Footer>
    <div class="playbar-container">
      <div ref="progressContainer"
        @pointerdown.prevent="(e) => startDrag(e)"
        @click.prevent="(e) => seek(e)"
        class="progress-container">
        <div ref="progress" class="progress" id="progress">
          <div ref="currTime" class="current-time"></div>
        </div>
      </div>
      <p class="playbar-title">Act 1: The Road We Travel</p>
      <p ref="audioDuration" class="playbar-duration"></p>
      <div @click="(e) => onToggleAudioPlay(e)" class="play-btn">
        <i v-if="!$store.state.isAudioPlaying" class="bi bi-play-fill play-pause-icon"></i>
        <i v-else class="bi bi-pause play-pause-icon"></i>
        <audio crossOrigin="anonymous" preload="auto" ref="act1Audio" id="act1Audio">
          <source src="@/assets/media/Act_OneDraft_Audio.mp3" type="audio/mpeg">
          Your browser does not support the audio element.
          <a href="@/assets/media/Act_OneDraft_Audio.mp3">link to download the audio</a> instead.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  watch,
  onMounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import { createPopper } from '@popperjs/core';
import Popper from 'vue3-popper';
import utils from '@/utils';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Popper,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      isAudioPlaying: false,
      act1Audio: null,
      progressContainer: null,
      progress: null,
      currTime: null,
      timer: null,
      audioDuration: null,
      percent: 0,
      windowWidth: null,
    });

    const width = computed(() => data.windowWidth);
    const onResize = () => {
      data.windowWidth = window.innerWidth;
    };

    const handleProgressBar = (duration, element) => {
      const increment = 10 / duration;
      data.percent = Math.min(increment * element.currentTime * 10, 100);
      data.progress.style.width = `${data.percent}%`;
      if (width.value > 500) {
        data.currTime.style.left = `${((data.percent) / 388) * width.value}px`;
      } else {
        data.currTime.style.left = `${((data.percent - 2) / 100) * width.value}px`;
      }
      if (data.percent <= 100) {
        data.timer = setTimeout(() => { handleProgressBar(duration, element); }, 10);
      }
    };

    const seek = (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      if (!data.act1Audio) return;
      const bounds = e.target.getBoundingClientRect();
      const seekPos = Math.min((e.clientX - bounds.left) / bounds.width, 100);
      data.act1Audio.currentTime = data.act1Audio.duration * seekPos;
    };

    const stopDrag = () => {
      data.currTime.style.top = '-5px';
      data.currTime.style.width = '12px';
      data.currTime.style.height = '12px';
      data.progressContainer.removeEventListener('pointermove', seek);
      data.progressContainer.removeEventListener('pointerup', stopDrag);
    };

    const startDrag = () => {
      data.currTime.style.top = '-8px';
      data.currTime.style.width = '20px';
      data.currTime.style.height = '20px';
      data.progressContainer.addEventListener('pointermove', seek);
      data.progressContainer.addEventListener('pointerup', stopDrag);
    };

    const onToggleAudioPlay = () => {
      store.dispatch('setAudio', !store.state.isAudioPlaying);
      if (store.state.isAudioPlaying) {
        data.act1Audio.play();
      } else {
        data.act1Audio.pause();
      }
      data.act1Audio.addEventListener('playing', (e) => {
        const { duration } = e.target;
        handleProgressBar(duration, data.act1Audio, e);
      });
      data.act1Audio.addEventListener('pause', () => {
        clearTimeout(data.timer);
      });
      data.act1Audio.onended = () => {
        data.currTime.style.left = '0px';
        data.progress.style.width = 0;
        data.act1Audio.currentTime = 0;
        store.state.isAudioPlaying = false;
        router.push({ path: '/act2' });
      };
    };

    const onNavigateToActOne = () => {
      router.push({ path: '/act1' });
    };

    const onNavigateToActTwo = () => {
      const introIndex = 0;
      store.dispatch('setCurrentIndex', introIndex);
      router.push({ path: '/act2' });
    };

    const copyToClipboard = (str) => {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    };

    const onCopyAddress = () => {
      copyToClipboard('1698 Flat Rock Rd, Camden, SC 29020');
    };

    onMounted(() => {
      data.windowWidth = window.innerWidth;
      window.addEventListener('resize', onResize);
      if (width.value > 400) {
        document.body.classList.add('no-scroll');
        store.dispatch('setPopup', true);
      }
      const link = document.getElementById('link');
      link.addEventListener('copy', (e) => {
        e.clipboardData.setData('text/plain', '1698 Flat Rock Rd, Camden, SC 29020');
        e.preventDefault();
      });
    });

    watch(() => [data.act1Audio, data.audioDuration], (audio) => {
      /* eslint-disable no-param-reassign */
      const updateAudioTimer = setInterval(() => {
        const currentTime = utils.convertTime(audio[0].currentTime);
        const totalTime = utils.convertTime(audio[0].duration);
        audio[1].innerHTML = `${currentTime} / ${totalTime}`;
      }, 100);
      return () => {
        clearInterval(updateAudioTimer);
      };
      /* eslint-enable no-param-reassign */
    });

    return {
      ...toRefs(data),
      onToggleAudioPlay,
      handleProgressBar,
      onNavigateToActTwo,
      onNavigateToActOne,
      copyToClipboard,
      onCopyAddress,
      seek,
      startDrag,
      stopDrag,
      onResize,
      width,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../css/theme.css";

.container {
  position: absolute;
  top: 50px;
  height: 100%;
  width: 100%;
  margin-top: 0px;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  background-color: #f3cfb7 !important;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(236,95,86,255);
    margin: auto;
    overflow-x: hidden;
    .title {
      font-weight: bold;
      font-size: 60px;
      margin-bottom: 0;
    }
    .sub-title {
      font-weight: normal;
      font-size: 16px;
    }
    .header-description {
      font-weight: normal;
      font-size: 24px;
      width: 85%;
    }
  }
  .svg {
    transform: rotate(-90deg);
  }
  .geo_uri_container {
    display: flex;
    justify-content: center !important;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin: 70px 0px;
    color: rgba(236,95,86,255);
    .destination_title {
      font-weight: bold;
      font-size: 44px;
      margin-bottom: 0;
      width: 80%;
    }
    .icon {
      margin: 25px auto;
      width: 60px;
      height: 75px;
    }
    .geo_uri_link, .geo-desc {
      text-align: center;
      font-size: 24px;
      width: 80%;
      margin-top: 50px;
      margin-bottom: -50px;
      color: rgba(236,95,86,255) !important;
    }
    .geo_uri_link {
      margin-top: 0;
    }
    .geo-desc {
      margin-bottom: 0px;
    }
  }
  .acts-container {
    position: relative;
    height: 900px;
    background-color: rgba(11,83,64,255);
    color: rgba(236,95,86,255);
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: rgba(236,95,86,255);
      width: 300px;
      margin: 40px 0px;
    }
    .act-1-header {
      margin-bottom: 10px;
      font-size: 24px;
      cursor: pointer;
    }
    .act-1-title {
      margin: 0;
      font-size: 44px;
      width: 250px;
      text-align: center;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .play-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      cursor: pointer !important;
      .play-pause-icon {
        color: rgba(236,95,86,255);
        font-size: 50px;
        margin-top: -10px;
        margin-left: 5px;
      }
      .margin-right {
        margin-right: 5px;
      }
    }
    .swipe-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid rgba(236,95,86,255);
    }
  }
  .playbar-container {
    position: fixed;
    bottom: 0;
    height: 100px;
    max-width: 500px;
    border-top: 1px solid #fff;
    background-color: rgba(11,83,64,255);
    margin-left: auto;
    margin-right: auto;
    .progress-container {
      position: relative;
      background-color: transparent;
      width: 100vw;
      max-width: 500px;
      height: 40px;
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height: 4px;
        max-width: 500px;
        background-color: #fff;
        transition: width .1s linear;
        .current-time {
          position: relative;
          top: -5px;
          left: 0px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
          transition: left .1s linear, width .1s ease-in-out;
        }
      }
    }
    .playbar-title, .playbar-duration {
      text-align: start;
      color: #fff;
      font-size: 16px;
      margin-left: 20px;
      margin-top: 0;
    }
    .playbar-duration {
      font-size: 10px;
    }
    .play-btn {
      position: absolute;
      top: 20%;
      right: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      cursor: pointer;
      .play-pause-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        color: rgba(236,95,86,255);
        font-size: 38px;
      }
    }
  }
  .copy-btn {
    padding: 10px 30px;
    border: 1px solid rgba(236,95,86,255);
    border-radius: 4px;
    background-color: #f3cfb7;
    color: rgba(236,95,86,255);
    font-family: BeirutDisplayTrial;
    &:hover {
      background-color: rgba(236,95,86,255);
      color: #f3cfb7;
      cursor: pointer;
    }
  }
}

</style>
