<template>
  <div class="container">
    <SceneHeader></SceneHeader>
    <div class="scene-container">
      <p class="scene-num">Scene 6</p>
      <p class="scene-title">Honor & Sacrifice</p>
      <div class="characters-container">
        <p class="country">Patriots</p>
        <p class="names">Patriots, Dragoons, Primus, Col. Otho Holland Williams,
          Pvt. Guilford Dudley, Wagoneers</p>
        <p class="country">British</p>
        <p class="names">Lt. Col. Banastre Tarleton, Lt. Gen. Charles Cornwallis, Loyalists</p>
      </div>
      <!-- <p class="scene-desc">Battle sounds continue.</p> -->
      <div class="script-container">
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Battle sounds continue.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">They don’t fall cheaply.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">Maryland and Delaware stand fast.
            And what’s left of North Carolina keeps the field.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">But DeKalb is their last gasp. Now it is rout and slaughter.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Patriots</dt>
          <dd class="text">Redcoats pull me from the swamp with General Gist.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">Snare us on the road with Rutherford.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">Colonel Armand bids to save the rebel wagons –</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Furious horses. Women, children. Clatter. Chaos.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dragoons</dt>
          <dd class="text">TOURNER AUTOUR!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">UNLOAD!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">ANYTHING FOR A FAST HORSE!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneers</dt>
          <dd class="text">CUT THEM LOOSE!!</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker"></dt>
          <dd class="text">NO, YOU SILLY WITCH –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">But he is dislodged.</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Soldiers swarm</em>.
            A WAGONEER <em>claws at a</em> BRITISH SOLDIER.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">My horse shot from under me. I empty my pistols –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Grab a sword –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Surrounded.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Taste blood on pine.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Dudley</dt>
          <dd class="text">Fight ‘til the cavalry come for my throat and
            I have no choice but run.</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Sounds gradually disperse.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Loyalists</dt>
          <dd class="text">A rebel come to my house bleeding from his ear.
            I get my stick, tell him back to the swamp with rubbish.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">Perhaps a hundred British killed,
            three hundred wounded, including excellent officers –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Williams</dt>
          <dd class="text">I can’t say how much of Maryland is left.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Cornwallis</dt>
          <dd class="text">A price indeed, but considering their number –</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Tarleton</dt>
          <dd class="text">Our superiority is assured.</dd>
        </dl>
        <dl class="scene-script margin-l">
          <dt class="speaker"></dt>
          <dd class="text"><em>Battle sounds fade. Crickets. Moans.</em></dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneer</dt>
          <dd class="text">Whisky and wound dressings gone. I tear up
            shirts to boil, make the men bite wood. I’m used to it.
            Only God has more blood on his hands than me.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Primus</dt>
          <dd class="text">Thank you.</dd>
        </dl>
        <dl class="scene-script">
          <dt class="speaker">Wagoneer</dt>
          <dd class="text">But when my husband comes with no
            leg I faint away. Wake in the dark asking for mother.</dd>
        </dl>
      </div>
      <div class="bios">
        <h1 class="bio-title">Who's Who: Patriots</h1>
        <p class="bio-desc">
          GUILFORD DUDLEY (1756-1833) was a minuteman at Halifax, NC, from 1775.
          In 1780 he enlisted as a private in the dragoons of the North Carolina
          militia. He fought bravely at Camden but returned home after the defeat.
          Returning to service after the British invasion of North Carolina in 1781,
          he was quickly commissioned a major, then Lieutenant Colonel, then a
          colonel charged with suppressing loyalists.
        </p>
        <p class="bio-desc">
          PRIMUS (b. 1760) enlisted in the Rowan County, NC militia
          in 1777, initially as a servant. But his long service—he
          was taken prisoner at Charleston and Camden, and fought through
          to Yorktown—indicates a brave professional soldier. There’s
          scant record of the black patriots at Camden; he speaks for many
          like him.
        </p>
        <p class="bio-desc">
          OTHO HOLLAND WILLIAMS (1749-94) was the former clerk of Frederick County,
          MD, and a merchant. He volunteered for the Continental Army in 1775,
          and was commissioned a Captain in a rifle regiment. He saw action at
          Boston and Fort Washington, where he was taken prisoner and sent to
          New York. His rank afforded him limited parole—in the streets he
          sneered at his captors, and spied for the Continental Army.
          Caught, he was imprisoned. This broke his health; but on his release
          in 1778, he went straight to the southern campaign, where he distinguished
          himself with bravery and strategic sense. A favorite of George Washington,
          he retired as a Brigadier General.
        </p>
        <h1 class="bio-title">British</h1>
        <p class="bio-desc">
          BANASTRE TARLETON (1754-1833), son of the Mayor of Liverpool,
          was educated at the Middle Temple and University College, Oxford.
          An infamous gambler and womanizer, he squandered his £5000
          inheritance (£430,000 today) in a year. With the last he bought
          a cornetcy in the 1st Dragoons, and found his way as a ruthless,
          even bloodthirsty soldier. His unit, known as ‘Tarleton’s Raiders,’
          was finally defeated by Lauzun at Yorktown. He later exploited his
          two missing fingers (lost at Guilford Court House) for a seat in parliament.
        </p>
        <p class="bio-desc">
          CHARLES CORNWALLIS (1738-1805), son of an Earl, was a veteran
          of the Seven Years’ War. He was second-in-command of British troops
          for most of the revolution, and their most gifted strategist.
          But Camden likely turned him cocky, as Saratoga did to Gates.
          He was later Lord Lieutenant of Ireland and Governor-General of India.
        </p>
        <p class="bio-desc">
          LOYALISTS and BLACK LOYALISTS were common. Cornwallis freed
          any slave who would fight with him. For obvious reasons accounts
          of black loyalists are rare.
        </p>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import SceneHeader from '@/components/SceneHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    SceneHeader,
    Footer,
  },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/scenes.scss';
.footer {
    margin-top: 0px;
  }
</style>
