<template>
  <div class="header-container">
    <p @click="onGoToIntro" class="header-title">Act 2: The Path We Walk</p>
    <svg xmlns="http://www.w3.org/2000/svg"
      :style="width < 400 ? { top: '18px' } : { top: '20px' }"
      class="swipe-left-arrow"
      viewBox="0 0 1700 100">
      <defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="7"
        refX="0" refY="3.5" fill="rgba(236,95,86,255)" orient="auto">
          <polygon points="0 0, 3 3.5, 0 7" />
        </marker>
      </defs>
      <line x1="600" y1="50" x2="250" y2="50" stroke="rgba(236,95,86,255)"
      stroke-dasharray="25"
      stroke-width="8" marker-end="url(#arrowhead)" />
    </svg>
    <p class="scene-number">{{ currentScene }}</p>
    <svg xmlns="http://www.w3.org/2000/svg"
      :style="width < 400 ? { bottom: '53px' } : { bottom: '58px' }"
      class="swipe-right-arrow"
      viewBox="0 0 1700 100">
      <defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="7"
        refX="0" refY="3.5" fill="rgba(236,95,86,255)" orient="auto">
          <polygon points="0 0, 3 3.5, 0 7" />
        </marker>
      </defs>
      <line x1="1100" y1="50" x2="1500" y2="50" stroke="rgba(236,95,86,255)"
      stroke-dasharray="25"
      stroke-width="8" marker-end="url(#arrowhead)" />
    </svg>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      windowWidth: null,
    });

    const currentScene = computed(() => {
      const formattedNumber = (store.state.currentIndex + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      return `${formattedNumber} / ${store.state.items.length}`;
    });

    const onGoToIntro = () => {
      const introIndex = 0;
      store.dispatch('setCurrentIndex', introIndex);
      router.push({ path: '/act2' });
    };
    const onResize = () => {
      data.windowWidth = window.innerWidth;
    };
    onMounted(() => {
      data.windowWidth = window.innerWidth;
      window.addEventListener('resize', onResize);
    });
    onUnmounted(() => window.removeEventListener('resize', onResize));
    const width = computed(() => data.windowWidth);
    return {
      ...toRefs(data),
      currentScene,
      onGoToIntro,
      onResize,
      width,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  position: sticky;
  top: 0;
  padding-bottom: 25px;
  height: 100px;
  width: 100%;
  background-color: #f3cfb7;
  font-family: BeirutDisplayTrial;
  font-style: normal;
  color: rgba(236,95,86,255);
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  .header-title {
    padding-top: 15px;
    margin-left: 5%;
    font-size: 18px;
    font-weight: bold;
    text-align: start;
    cursor: pointer;
  }
  .swipe-left-arrow {
    position: relative;
    top: 18px;
  }
  .swipe-right-arrow {
    position: relative;
    bottom: 53px;
  }
  .scene-number {
    font-family: Karla-Medium;
    font-size: 14px;
    position: relative;
    bottom: 20px;
  }
}
</style>
